import React from 'react';

import DocumentHeader from '../components/DocumentHeader';
import Button from '../components/Button';

export default function NotFoundPage() {
  return (
    <div className="NotFoundPage container">
      <DocumentHeader title="Page Not Found" />
      <div className="NotFoundPage__panel">
        <svg viewBox="0 0 505 274" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path d="M254.12 274C391.055 274 502.063 266.667 502.063 257.622C502.063 248.577 391.055 241.244 254.12 241.244C117.185 241.244 6.17773 248.577 6.17773 257.622C6.17773 266.667 117.185 274 254.12 274Z" fill="#F2F2F2" />
            <path d="M244.618 78.3263C204.221 78.3263 175.217 104.186 175.217 164.526C175.217 232.911 204.221 251.013 244.618 251.013C285.014 251.013 315.746 231.187 315.746 164.526C315.746 93.2676 285.014 78.3263 244.618 78.3263ZM244.883 228.314C216.904 228.314 202.286 211.936 202.286 164.57C202.286 122.778 217.502 100.738 245.482 100.738C273.461 100.738 288.677 115.215 288.677 164.57C288.677 210.741 272.863 228.314 244.883 228.314Z" fill="#2F2E41" />
            <path d="M151.027 203.316H134.325V154.241C134.325 151.634 133.287 149.135 131.44 147.291C129.592 145.448 127.087 144.413 124.475 144.413H120.561C119.267 144.413 117.987 144.667 116.792 145.161C115.597 145.655 114.511 146.379 113.596 147.291C112.681 148.204 111.956 149.288 111.461 150.48C110.966 151.672 110.711 152.95 110.711 154.241V203.316H63.5996C62.6304 203.316 61.6777 203.066 60.8338 202.591C59.99 202.115 59.2837 201.43 58.7834 200.601C58.2831 199.773 58.0059 198.83 57.9787 197.863C57.9514 196.897 58.175 195.939 58.6277 195.084L108.49 100.92C109.112 99.7459 109.488 98.4584 109.598 97.1351C109.707 95.8118 109.546 94.4801 109.125 93.2205C108.704 91.9609 108.032 90.7994 107.149 89.8061C106.266 88.8129 105.19 88.0085 103.987 87.4416L101.043 86.0547C98.7578 84.9777 96.1448 84.8187 93.745 85.6105C91.3452 86.4023 89.3423 88.0843 88.1507 90.3084L27.3371 203.82C26.3015 205.753 25.7598 207.911 25.7598 210.103C25.7598 211.853 26.1052 213.586 26.7765 215.203C27.4477 216.819 28.4315 218.289 29.6718 219.526C30.912 220.764 32.3844 221.745 34.0049 222.415C35.6254 223.085 37.3622 223.429 39.1162 223.429H110.711V254.461C110.711 256.008 111.016 257.54 111.61 258.97C112.203 260.399 113.073 261.698 114.169 262.791C115.266 263.885 116.567 264.753 118 265.345C119.432 265.937 120.967 266.242 122.518 266.242H122.518C124.068 266.242 125.604 265.937 127.036 265.345C128.469 264.753 129.77 263.885 130.866 262.791C131.963 261.698 132.833 260.399 133.426 258.97C134.019 257.54 134.325 256.008 134.325 254.461V223.429H151.027C153.7 223.429 156.264 222.37 158.154 220.484C160.044 218.598 161.106 216.04 161.106 213.373C161.106 210.706 160.044 208.148 158.154 206.262C156.264 204.376 153.7 203.316 151.027 203.316Z" fill="#6C63FF" />
            <path d="M460.307 203.316H443.605V154.241C443.605 151.634 442.567 149.135 440.72 147.291C438.873 145.448 436.367 144.413 433.755 144.413H429.841C428.548 144.413 427.267 144.667 426.072 145.161C424.877 145.655 423.791 146.379 422.876 147.291C421.962 148.204 421.236 149.288 420.741 150.48C420.246 151.672 419.991 152.95 419.991 154.241V203.316H372.88C371.911 203.316 370.958 203.066 370.114 202.591C369.27 202.115 368.564 201.43 368.064 200.601C367.563 199.773 367.286 198.83 367.259 197.863C367.232 196.897 367.455 195.939 367.908 195.084L417.77 100.92C418.392 99.7459 418.769 98.4584 418.878 97.1351C418.987 95.8118 418.826 94.4801 418.406 93.2205C417.985 91.9609 417.312 90.7994 416.429 89.8061C415.546 88.8129 414.47 88.0085 413.267 87.4416L410.324 86.0547C408.038 84.9777 405.425 84.8187 403.025 85.6105C400.625 86.4023 398.623 88.0843 397.431 90.3084L336.617 203.82C335.582 205.753 335.04 207.911 335.04 210.103C335.04 213.637 336.447 217.027 338.952 219.526C341.457 222.025 344.854 223.429 348.396 223.429H419.991V254.461C419.991 257.586 421.235 260.582 423.449 262.791C425.664 265.001 428.667 266.242 431.798 266.242C434.929 266.242 437.933 265.001 440.147 262.791C442.361 260.582 443.605 257.586 443.605 254.461V223.429H460.307C462.98 223.429 465.544 222.37 467.434 220.484C469.324 218.598 470.386 216.04 470.386 213.373C470.386 210.706 469.324 208.148 467.434 206.262C465.544 204.376 462.98 203.316 460.307 203.316Z" fill="#6C63FF" />
            <path d="M155.634 197.569H138.932V148.494C138.932 147.204 138.677 145.926 138.182 144.733C137.687 143.541 136.962 142.457 136.047 141.545C135.132 140.632 134.047 139.908 132.851 139.414C131.656 138.92 130.376 138.666 129.082 138.666H125.168C122.556 138.666 120.051 139.702 118.203 141.545C116.356 143.388 115.318 145.888 115.318 148.494V197.569H68.207C67.2378 197.569 66.2851 197.32 65.4412 196.844C64.5974 196.368 63.8911 195.683 63.3908 194.855C62.8906 194.027 62.6133 193.083 62.5861 192.117C62.5588 191.15 62.7824 190.193 63.2352 189.338L113.097 95.1732C113.719 93.9992 114.096 92.7117 114.205 91.3884C114.314 90.0651 114.154 88.7334 113.733 87.4738C113.312 86.2142 112.64 85.0527 111.756 84.0595C110.873 83.0662 109.798 82.2618 108.594 81.6949L105.651 80.308C103.365 79.2311 100.752 79.0721 98.3524 79.8638C95.9526 80.6556 93.9497 82.3376 92.7581 84.5617L31.9445 198.073C30.909 200.006 30.3672 202.164 30.3672 204.356V204.356C30.3672 207.89 31.7744 211.28 34.2792 213.779C36.784 216.279 40.1812 217.683 43.7236 217.683H115.318V248.715C115.318 250.262 115.624 251.794 116.217 253.223C116.811 254.652 117.68 255.951 118.777 257.045C119.873 258.139 121.175 259.007 122.607 259.599C124.039 260.191 125.575 260.495 127.125 260.495H127.125C130.257 260.495 133.26 259.254 135.474 257.045C137.688 254.836 138.932 251.839 138.932 248.715V217.683H155.634C156.958 217.683 158.269 217.423 159.491 216.917C160.714 216.412 161.825 215.671 162.761 214.737C163.697 213.803 164.44 212.695 164.946 211.475C165.453 210.255 165.713 208.947 165.713 207.626V207.626C165.713 206.305 165.453 204.998 164.946 203.778C164.44 202.557 163.697 201.449 162.761 200.515C161.825 199.581 160.714 198.84 159.491 198.335C158.269 197.83 156.958 197.569 155.634 197.569V197.569Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M468.946 197.569H452.244V148.494C452.244 145.888 451.206 143.388 449.359 141.545C447.511 139.702 445.006 138.666 442.394 138.666H438.48C437.186 138.666 435.906 138.92 434.711 139.414C433.515 139.908 432.43 140.632 431.515 141.545C430.6 142.457 429.875 143.541 429.38 144.733C428.885 145.926 428.63 147.204 428.63 148.494V197.569H381.519C380.549 197.569 379.597 197.32 378.753 196.844C377.909 196.368 377.203 195.683 376.702 194.855C376.202 194.027 375.925 193.083 375.898 192.117C375.87 191.15 376.094 190.193 376.547 189.338L426.409 95.1732C427.031 93.9992 427.407 92.7117 427.517 91.3884C427.626 90.0651 427.465 88.7334 427.044 87.4738C426.623 86.2142 425.951 85.0527 425.068 84.0595C424.185 83.0662 423.109 82.2618 421.906 81.6949L418.962 80.308C416.677 79.2311 414.064 79.0721 411.664 79.8638C409.264 80.6556 407.261 82.3376 406.07 84.5617L345.256 198.073C344.22 200.006 343.679 202.164 343.679 204.356C343.679 207.89 345.086 211.28 347.591 213.779C350.096 216.279 353.493 217.683 357.035 217.683H428.63V248.715C428.63 251.839 429.874 254.836 432.088 257.045C434.302 259.254 437.305 260.495 440.437 260.495V260.495C443.568 260.495 446.571 259.254 448.785 257.045C451 254.836 452.244 251.839 452.244 248.715V217.683H468.946C470.269 217.683 471.58 217.423 472.803 216.917C474.026 216.412 475.137 215.671 476.073 214.737C477.009 213.803 477.751 212.695 478.258 211.475C478.764 210.255 479.025 208.947 479.025 207.626V207.626C479.025 204.959 477.963 202.401 476.073 200.515C474.183 198.629 471.619 197.569 468.946 197.569V197.569Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M253.833 73.1543C213.436 73.1543 184.432 99.0143 184.432 159.354C184.432 227.739 213.436 245.841 253.833 245.841C294.229 245.841 324.961 226.015 324.961 159.354C324.961 88.0956 294.229 73.1543 253.833 73.1543ZM254.098 223.142C226.118 223.142 211.501 206.764 211.501 159.398C211.501 117.606 226.717 95.5663 254.696 95.5663C282.676 95.5663 297.892 110.043 297.892 159.398C297.892 205.569 282.078 223.142 254.098 223.142V223.142Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M473.26 196.766C474.684 197.282 476.208 197.462 477.714 197.29C479.219 197.118 480.664 196.6 481.934 195.776C483.204 194.952 484.265 193.845 485.033 192.542C485.802 191.239 486.256 189.776 486.361 188.268C486.466 186.76 486.219 185.248 485.638 183.852C485.058 182.456 484.16 181.213 483.016 180.222C481.872 179.231 480.513 178.518 479.046 178.141C477.579 177.763 476.044 177.731 474.563 178.046L474.807 183.07L472.425 178.764C470.087 179.86 468.281 181.838 467.404 184.262C467.092 185.111 466.903 186 466.841 186.902C466.691 189.019 467.236 191.127 468.394 192.907C469.552 194.687 471.261 196.042 473.26 196.766Z" fill="#57B894" />
            <path d="M485.047 255.035C484.206 250.762 487.85 246.974 491.449 244.512C495.049 242.05 499.254 239.631 500.482 235.453C502.247 229.448 496.99 223.949 492.898 219.206C489.862 215.687 487.255 211.821 485.131 207.689C484.28 206.032 483.498 204.3 483.274 202.452C482.951 199.79 483.808 197.142 484.672 194.603C487.548 186.145 490.623 177.757 493.898 169.441" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M473.662 195.472C475.086 195.989 476.611 196.168 478.116 195.996C479.621 195.825 481.066 195.306 482.336 194.483C483.606 193.659 484.667 192.552 485.436 191.249C486.204 189.946 486.659 188.483 486.764 186.975C486.869 185.467 486.621 183.955 486.041 182.559C485.46 181.162 484.562 179.92 483.419 178.929C482.275 177.937 480.916 177.225 479.449 176.847C477.982 176.47 476.447 176.437 474.965 176.752L475.21 181.776L472.828 177.471C470.49 178.567 468.683 180.544 467.806 182.968C467.494 183.818 467.305 184.707 467.243 185.609C467.093 187.726 467.638 189.834 468.796 191.614C469.955 193.393 471.663 194.748 473.662 195.472V195.472Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M483.851 167.706C484.41 166.168 485.348 164.795 486.578 163.713C487.809 162.631 489.292 161.876 490.892 161.517L491.698 166.293L493.191 161.277C495.264 161.307 497.274 161.995 498.931 163.239C500.587 164.484 501.804 166.222 502.406 168.202C503.008 170.182 502.964 172.301 502.28 174.254C501.596 176.208 500.308 177.893 498.601 179.068C496.894 180.243 494.857 180.847 492.784 180.791C490.711 180.736 488.71 180.025 487.068 178.76C485.427 177.496 484.231 175.744 483.653 173.757C483.074 171.77 483.144 169.651 483.851 167.706H483.851Z" fill="#57B894" />
            <path d="M484.388 165.982C484.947 164.443 485.885 163.07 487.115 161.988C488.346 160.907 489.83 160.151 491.429 159.792L492.235 164.568L493.728 159.552C495.802 159.583 497.811 160.27 499.468 161.515C501.124 162.759 502.341 164.497 502.943 166.477C503.545 168.457 503.501 170.577 502.817 172.53C502.133 174.483 500.845 176.169 499.138 177.344C497.431 178.519 495.394 179.122 493.321 179.067C491.249 179.011 489.247 178.3 487.606 177.036C485.964 175.771 484.768 174.019 484.19 172.032C483.612 170.045 483.681 167.927 484.388 165.982H484.388Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M491.18 219.303C493.438 220.12 495.919 220.08 498.149 219.19C500.379 218.299 502.203 216.621 503.273 214.475C504.343 212.33 504.584 209.866 503.951 207.554C503.317 205.242 501.853 203.244 499.838 201.94L498.69 205.794L497.877 200.962C497.869 200.958 497.86 200.955 497.852 200.953C496.644 200.515 495.361 200.318 494.078 200.375C492.794 200.432 491.534 200.74 490.37 201.283C489.205 201.826 488.16 202.592 487.292 203.538C486.425 204.485 485.753 205.592 485.315 206.797C484.877 208.003 484.681 209.282 484.738 210.563C484.796 211.844 485.106 213.101 485.651 214.263C486.195 215.424 486.964 216.467 487.913 217.332C488.862 218.197 489.972 218.866 491.18 219.303H491.18Z" fill="#57B894" />
            <path d="M491.646 217.556C493.904 218.373 496.385 218.333 498.615 217.443C500.845 216.552 502.669 214.874 503.739 212.729C504.809 210.583 505.05 208.119 504.416 205.807C503.783 203.495 502.319 201.497 500.304 200.193L499.156 204.048L498.342 199.215C498.334 199.211 498.326 199.208 498.318 199.206C497.11 198.768 495.827 198.571 494.544 198.628C493.26 198.685 492 198.994 490.835 199.536C489.671 200.079 488.626 200.845 487.758 201.792C486.891 202.738 486.219 203.845 485.781 205.051C485.342 206.256 485.147 207.536 485.204 208.817C485.262 210.097 485.572 211.355 486.116 212.516C486.661 213.677 487.43 214.72 488.379 215.585C489.327 216.45 490.438 217.12 491.646 217.556H491.646Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M480.996 235.513C481.384 237.717 482.52 239.721 484.214 241.189C485.907 242.657 488.055 243.499 490.297 243.574C492.538 243.649 494.738 242.953 496.527 241.603C498.315 240.252 499.584 238.328 500.12 236.155C500.656 233.982 500.427 231.691 499.471 229.666C498.515 227.641 496.89 226.006 494.869 225.035C492.848 224.064 490.554 223.815 488.371 224.331C486.189 224.847 484.25 226.096 482.881 227.869L487.24 234.415L481.284 230.941C480.822 232.42 480.724 233.988 480.996 235.513Z" fill="#57B894" />
            <path d="M481.399 234.219C481.788 236.423 482.924 238.428 484.617 239.895C486.31 241.363 488.458 242.205 490.7 242.281C492.942 242.356 495.141 241.66 496.93 240.309C498.718 238.958 499.987 237.035 500.523 234.862C501.059 232.688 500.83 230.397 499.874 228.373C498.918 226.348 497.293 224.713 495.272 223.742C493.251 222.77 490.957 222.522 488.775 223.038C486.592 223.554 484.654 224.803 483.284 226.576L487.643 233.121L481.688 229.647C481.226 231.126 481.127 232.694 481.399 234.219V234.219Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M4.23346 220.543C5.16896 220.883 6.17068 221 7.15964 220.888C8.1486 220.775 9.09778 220.434 9.93237 219.893C10.767 219.352 11.4641 218.624 11.9689 217.768C12.4738 216.912 12.7724 215.951 12.8414 214.96C12.9103 213.969 12.7477 212.976 12.3663 212.059C11.9849 211.141 11.3951 210.325 10.6435 209.674C9.89195 209.022 8.99904 208.554 8.0352 208.306C7.07135 208.058 6.06292 208.037 5.08939 208.244L5.25027 211.545L3.68512 208.716C2.14901 209.436 0.962308 210.735 0.385785 212.328C0.181038 212.886 0.0565665 213.47 0.0160848 214.063C-0.0827082 215.454 0.275555 216.839 1.03658 218.008C1.79761 219.177 2.91993 220.067 4.23346 220.543Z" fill="#57B894" />
            <path d="M11.9784 258.827C11.4259 256.02 13.82 253.531 16.1848 251.914C18.5496 250.296 21.3128 248.707 22.1196 245.961C23.2793 242.016 19.8251 238.403 17.1366 235.287C15.142 232.975 13.4291 230.435 12.0338 227.72C11.4272 226.654 11.014 225.489 10.8133 224.279C10.6013 222.53 11.1644 220.79 11.7317 219.122C13.6215 213.565 15.642 208.054 17.7933 202.59" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M4.49909 219.693C5.43459 220.033 6.4363 220.151 7.42527 220.038C8.41423 219.925 9.36341 219.584 10.198 219.043C11.0326 218.502 11.7297 217.775 12.2346 216.919C12.7394 216.063 13.038 215.101 13.107 214.11C13.1759 213.12 13.0133 212.126 12.6319 211.209C12.2505 210.292 11.6608 209.475 10.9092 208.824C10.1576 208.173 9.26466 207.705 8.30082 207.456C7.33698 207.208 6.32855 207.187 5.35502 207.394L5.5159 210.695L3.95074 207.866C2.41464 208.586 1.22793 209.885 0.65141 211.478C0.446665 212.036 0.322196 212.62 0.281712 213.213C0.182912 214.604 0.541173 215.989 1.3022 217.158C2.06323 218.328 3.18555 219.218 4.49909 219.693V219.693Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M11.193 201.451C11.5602 200.44 12.1763 199.538 12.9848 198.827C13.7932 198.116 14.768 197.62 15.8191 197.384L16.3483 200.522L17.3295 197.226C18.6917 197.246 20.0123 197.698 21.1005 198.516C22.1888 199.334 22.9884 200.475 23.3839 201.776C23.7794 203.077 23.7504 204.47 23.301 205.753C22.8516 207.036 22.0051 208.144 20.8837 208.916C19.7624 209.688 18.4241 210.084 17.0622 210.048C15.7003 210.011 14.3852 209.544 13.3068 208.713C12.2284 207.883 11.4425 206.731 11.0626 205.426C10.6826 204.121 10.7283 202.728 11.193 201.451Z" fill="#57B894" />
            <path d="M11.5456 200.317C11.9127 199.307 12.5289 198.404 13.3373 197.694C14.1458 196.983 15.1205 196.487 16.1717 196.251L16.7009 199.389L17.682 196.093C19.0442 196.113 20.3648 196.565 21.4531 197.383C22.5413 198.2 23.3409 199.342 23.7365 200.643C24.132 201.944 24.1029 203.337 23.6536 204.62C23.2042 205.903 22.3577 207.011 21.2363 207.783C20.1149 208.555 18.7766 208.951 17.4147 208.915C16.0528 208.878 14.7377 208.411 13.6593 207.58C12.581 206.75 11.7951 205.598 11.4151 204.293C11.0352 202.987 11.0809 201.595 11.5456 200.317V200.317Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M16.0085 235.351C17.4921 235.888 19.1219 235.861 20.5871 235.276C22.0524 234.691 23.2508 233.589 23.9537 232.179C24.6567 230.769 24.8152 229.15 24.3989 227.631C23.9827 226.112 23.0208 224.799 21.6966 223.943L20.9428 226.475L20.4082 223.3C20.4029 223.298 20.3974 223.296 20.3918 223.294C19.5982 223.006 18.7556 222.877 17.912 222.914C17.0684 222.951 16.2404 223.154 15.4754 223.511C14.7103 223.867 14.0232 224.371 13.4532 224.992C12.8833 225.614 12.4418 226.342 12.1538 227.134C11.8658 227.926 11.7371 228.767 11.775 229.608C11.8129 230.45 12.0167 231.276 12.3747 232.039C12.7326 232.802 13.2378 233.487 13.8613 234.056C14.4848 234.624 15.2145 235.064 16.0085 235.351Z" fill="#57B894" />
            <path d="M16.3143 234.203C17.798 234.74 19.4277 234.713 20.893 234.128C22.3583 233.543 23.5566 232.441 24.2596 231.031C24.9626 229.621 25.121 228.003 24.7048 226.484C24.2885 224.965 23.3267 223.652 22.0025 222.795L21.2486 225.327L20.714 222.152C20.7087 222.15 20.7033 222.148 20.6977 222.146C19.9043 221.859 19.062 221.731 18.2188 221.768C17.3757 221.806 16.5482 222.009 15.7837 222.366C14.2396 223.086 13.0456 224.389 12.4643 225.988C11.8831 227.587 11.9621 229.35 12.6842 230.891C13.4062 232.432 14.712 233.623 16.3143 234.203V234.203Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M9.31651 246.001C9.5716 247.449 10.318 248.766 11.4306 249.73C12.5432 250.694 13.9543 251.248 15.4272 251.297C16.9001 251.347 18.3453 250.889 19.5204 250.002C20.6955 249.114 21.529 247.851 21.8812 246.423C22.2333 244.995 22.0827 243.49 21.4546 242.159C20.8265 240.829 19.7591 239.755 18.4313 239.117C17.1036 238.478 15.5963 238.315 14.1623 238.654C12.7282 238.993 11.4546 239.814 10.555 240.979L13.4186 245.279L9.5059 242.997C9.20252 243.968 9.13757 244.999 9.31651 246.001Z" fill="#57B894" />
            <path d="M9.58214 245.151C9.83723 246.599 10.5837 247.916 11.6962 248.88C12.8088 249.845 14.2199 250.398 15.6928 250.447C17.1658 250.497 18.611 250.039 19.786 249.152C20.9611 248.264 21.7946 247.001 22.1468 245.573C22.499 244.145 22.3484 242.64 21.7202 241.309C21.0921 239.979 20.0247 238.905 18.697 238.267C17.3692 237.629 15.8619 237.465 14.4279 237.804C12.9938 238.143 11.7202 238.964 10.8207 240.129L13.6843 244.429L9.77152 242.147C9.46814 243.119 9.40319 244.149 9.58214 245.151V245.151Z" stroke="#3F3D56" strokeMiterlimit="10" />
            <path d="M254.263 173.206C254.266 173.796 254.045 174.365 253.642 174.797C253.452 175.004 253.221 175.17 252.964 175.283C252.707 175.397 252.429 175.456 252.148 175.457H247.447C246.868 175.437 246.32 175.189 245.924 174.767C245.528 174.345 245.315 173.784 245.332 173.206C245.33 172.749 245.464 172.301 245.717 171.921C246.758 171.775 247.807 171.703 248.857 171.705C250.619 171.7 252.375 171.901 254.089 172.306C254.205 172.592 254.264 172.898 254.263 173.206Z" fill="white" />
            <path d="M227.941 146.849C229.239 146.849 230.291 145.799 230.291 144.504C230.291 143.209 229.239 142.159 227.941 142.159C226.643 142.159 225.591 143.209 225.591 144.504C225.591 145.799 226.643 146.849 227.941 146.849Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="505" height="274" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h1 className="NotFoundPage__title">Page not found</h1>
        <Button uppercase href="/">Take me Home</Button>
      </div>
    </div>
  );
}
